:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
  __large: l;
}

.root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  z-index: 5;
}

.wrapper {
  position: absolute;
  top: 120px;
  bottom: -60px;
  left: -30px;
  right: -30px;
  background: center bottom/auto auto no-repeat
    url('src/assets/svg/assets/login-page-background.svg');

  @media (min-width: __small) {
    bottom: -100px;
  }

  @media (min-width: __medium) {
    bottom: -160px;
    left: -60px;
    right: -60px;

    @media (min-height: 600px) {
      bottom: -120px;
    }

    @media (min-height: 900px) {
      bottom: -80px;
    }
  }

  @media (min-width: __large) {
    bottom: -250px;

    @media (min-height: 600px) {
      bottom: -220px;
    }

    @media (min-height: 900px) {
      bottom: -160px;
    }
  }
}
