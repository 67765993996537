.root {
  position: relative;
}

.badge {
  position: absolute;
  box-sizing: border-box;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  user-select: none;
  font-weight: 500;
  font-size: 0.75rem;
  min-width: 20px;
  line-height: 1;
  padding: 0px 6px;
  height: 20px;
  border-radius: 10px;
  z-index: 20;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #ffffff;
  color: #000000;
  top: 0px;
  right: 0px;
  transform: scale(1) translate(25%, -25%);
  transform-origin: 100% 0%;

  color: #ffffff;
  background-color: var(--primary-color);
}

.secondary {
  & .badge {
    color: var(--secondaryAccentColor);
    background-color: var(--secondaryColor);
  }
}
