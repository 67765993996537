:root {
  --white-color: #ffffff;
  /*
  --primary-color: var(--blue-color-500);
  --error-color: var(--red-color-500);
  --success-color: var(--green-color-500);

  --text-color: var(--gray-dark-color-700);
  --text-color-disabled: var(--gray-dark-color-500);
  --text-color-inverse: var(--white-color);
  --text-color-inverse-disabled: var(--gray-dark-100);
*/
  --dark-blue-color: #004b93;
  --dark-blue-color-0: #ffffff;
  --dark-blue-color-10: #ccdbe9;
  --dark-blue-color-20: #99b7d4;
  --dark-blue-color-30: #6693be;
  --dark-blue-color-40: #336fa9;
  --dark-blue-color-50: #004b93;
  --dark-blue-color-60: #003c76;
  --dark-blue-color-70: #002d58;
  --dark-blue-color-80: #001e3b;
  --dark-blue-color-90: #000f1d;
  --dark-blue-color-100: #000000;

  --red-color: #d32f2f;
  --red-color-0: #ffffff;
  --red-color-10: #f6d5d5;
  --red-color-20: #edacac;
  --red-color-30: #e58282;
  --red-color-40: #dc5959;
  --red-color-50: #d32f2f;
  --red-color-60: #a92626;
  --red-color-70: #7f1c1c;
  --red-color-80: #541313;
  --red-color-90: #2a0909;
  --red-color-100: #000000;

  --control-primary-color: var(--dark-blue-color-50);
  --control-primary-color-hover: var(--dark-blue-color-30);
  --control-primary-color-active: var(--dark-blue-color-70);
  --control-primary-color-disabled: var(--dark-blue-color-20);
  --control-primary-accent-color: #ffffff;

  /*
  background-color: #daeeff;

  &:hover,
  &:active,
  &:focus {
    background-color: #cfe2f2;
  }
  */
  --primaryLightColor: #daeeff;
  --primaryLightAccentColor: #004b93;
  --primaryLightHoverColor: #cfe2f2;
  --primaryLightActiveColor: #cfe2f2;

  --control-danger-color: var(--red-color-50);
  --control-danger-color-hover: var(--red-color-60);
  --control-danger-color-active: var(--red-color-70);
  --control-danger-color-disabled: var(--red-color-30);
  --control-danger-accent-color: #ffffff;

  --secondaryColor: #219653;
  --secondaryAccentColor: #ffffff;
  --secondaryHoverColor: #1d874a;
  --secondaryActiveColor: #1c7f46;
  --secondaryDisabledColor: #43d581;

  --secondaryLightColor: #c9f3db;
  /*
  --secondaryLightAccentColor: #219653;
  */
  --secondaryLightAccentColor: #104b29;
  --secondaryLightHoverColor: #b6eece;
  --secondaryLightActiveColor: #b6eece;
  /*
  --secondaryLightDisabledColor: #6ade9c;
  */

  --control-border-color: #e7eaee;
  /*
  --secondaryColor: #2fa55b;*/
}
