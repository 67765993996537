:import('~src/styles/breakpoints.module.css') {
  small: s;
  medium: md;
}

.header {
  justify-content: flex-end;
}

.locale {
  flex: 0 0 auto;
  margin-right: 0;
}

.loginButtonHidden {
  & .locale {
    /*
    margin-right: 166px;
    */
  }
}

.buttonContainer {
  --maxContainerWidth: 150px;

  width: var(--maxContainerWidth);
  margin-left: 16px;
}

.loginButton {
  min-width: 100px;
  max-width: var(--maxContainerWidth);
}
