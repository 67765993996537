.dot {
  @nest .admin & {
    background-color: #2d9cdb;
  }

  @nest .user & {
    background-color: #27ae60;
  }
}

.label {
  display: block;
  overflow-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
