:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

:import('~src/styles/common/passwordForms.export.module.css') {
  __layoutTopPaddingNoLogo: layoutTopPaddingNoLogo;

  __maxContentWidth: maxContentWidth;
  __maxFormBlockWidth: maxFormBlockWidth;
}

.root {
  background-color: #f8fbff;
  position: relative;
}

.mainLayoutContent {
  padding-top: __layoutTopPaddingNoLogo;
}

.content {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: __maxContentWidth;
  position: relative;
}

.contentInnerWrapper {
  width: 100%;
}

.formBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.formBlockInnerWrapper {
  flex: 1 1 auto;
  max-width: __maxFormBlockWidth;
  position: relative;
}

.linkBlock {
  margin-top: 32px;
  text-align: center;
}
