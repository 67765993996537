:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  min-width: unset;

  @media (min-width: __small) {
    min-width: 140px;
  }
}

.option {
  min-height: unset !important;

  & .item {
    font-size: 14px;
  }
}

.item {
  font-size: 16px;
  font-weight: 400 !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & .languageName {
    display: none;

    @media (min-width: __small) {
      display: block;
    }
  }
}

.languageIcon {
  width: 22px;
  height: 20px;

  flex: 0 0 auto;
}

.languageName {
  margin-left: 8px;
}
