.root {
  --aDescriptiorWidth: 28px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  overflow: hidden;
}

.descriptor {
  flex: 0 0 var(--aDescriptiorWidth);
}

.value {
  flex: 1 1 auto;
  line-height: 1.25;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
}

.rootSmall {
  & .value {
    font-size: 0.8rem;
  }
}

.onlyValue {
  padding-left: var(--aDescriptiorWidth);
}
