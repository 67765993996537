:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  position: relative;
  padding-right: var(--slib-inline-padding);
  padding-right: 4px;

  @media (min-width: __small) {
    padding-left: 6px;
  }

  @media (min-width: __medium) {
    padding-left: var(--slib-inline-padding);
  }
}

.hasActions {
  padding-right: 0px;
}

.actionContainer {
  /*
  position: absolute;
  top: 0px;
  right: 0;
  */

  margin-left: 4px;
  flex: 0 0 auto;
}

/*
.rootCheckable {
  padding-left: 8px;
}
*/

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.checkboxWrapper {
  line-height: 1;
  margin-right: 6px;
  flex: 0 0 auto;

  @media (min-width: __medium) {
    margin-right: 12px;
  }
}

.content {
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-items: flex-start;
  align-items: center;
  min-width: 0;
}

.avatar {
  flex: 0 0 auto;
  margin-right: 12px;
}

.data {
  flex: 1 1 auto;
  min-width: 0;
  line-height: 1.15;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  min-height: 24px;
}

.link {
  flex: 1 1 auto;
}

.chip {
  @media (min-width: __small) {
    margin-left: 8px;

    flex-wrap: nowrap;
    flex: 0 0 auto;
  }
}

.details {
}

.attributes {
  margin-top: 4px;
}

.attributesContainer {
  flex-wrap: wrap;

  @media (min-width: __medium) {
    flex-wrap: nowrap;
  }
}

.roleChip {
  min-width: 0;
  flex: 1 1 150px;
  flex-basis: 100%;

  @media (min-width: __medium) {
    flex-basis: 150px;
  }
}

.attribute {
  flex: 1 1 150px;
}

.chipContainer {
  margin-top: 4px;
  display: flex;
  justify-content: flex-start;
}

/*
.actions {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-items: flex-end;
  justify-content: flex-end;
  flex-wrap: nowrap;
  margin: 16px 0 0 0;
}

.actionButton {
  flex-shrink: 1;
  font-size: 13px;

  & + & {
    margin-left: 8px;
  }
}


*/
