:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  background-color: #f8fbff;
  position: relative;
}

.content {
  padding-top: 40px;
}

.wrapper {
  width: 100%;
}

.linkBlock {
  margin-top: 16px;
  text-align: center;
}
