.root {
  text-decoration: unset;
}

.contained {
  &.primary {
    color: var(--control-primary-accent-color);

    &:visited {
      color: var(--control-primary-accent-color);
    }

    &:hover {
      background-color: var(--control-primary-color-hover);
    }

    &:active {
      background-color: var(--control-primary-color-active);
    }
  }

  &.danger {
    color: var(--control-danger-accent-color);

    &:visited {
      color: var(--control-danger-accent-color);
    }

    &:hover {
      background-color: var(--control-danger-color-hover);
    }

    &:active {
      background-color: var(--control-danger-color-active);
    }
  }
}

.outlined {
  &.primary {
    color: var(--control-primary-color);

    &:visited {
      color: var(--control-primary-color-hover);
    }

    &:hover {
      color: var(--control-primary-color-hover);
    }

    &:active {
      color: var(--control-primary-color-active);
    }
  }

  &.danger {
    color: var(--control-danger-color);

    &:visited {
      color: var(--control-danger-color-hover);
    }

    &:hover {
      color: var(--control-danger-color-hover);
    }

    &:active {
      color: var(--control-danger-color-active);
    }
  }
}
