.root {
  padding-top: 4px;
  font-size: 0.75rem;
  line-height: 1.1;
  color: darkred;
}

.slideExitDone {
  display: none;
}
