:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  --contentTopPadding: 28px;

  padding-top: var(--contentTopPadding);

  @media (min-width: __small) {
    --contentTopPadding: 56px;
  }
}
