.root {
  background: linear-gradient(
    9deg,
    var(--secondaryColor),
    var(--primary-color)
  ) !important;

  &:active,
  &:focus,
  &:hover {
    background: linear-gradient(9deg, #1d874a, #004384) !important;
  }
}
