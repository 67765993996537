.root {
  line-height: 1;
}

.form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-items: stretch;
  flex-wrap: nowrap;
}

.formItem {
  flex: 1 1 auto;
  margin: 0;
}

.inputRoot {
  width: 100%;
}

.input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-width: 2px;
  border-right: unset;
}

.submitButton {
  flex: 0 0 auto;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
