.root {
  line-height: 1;
}

.item {
  /* primary */
  color: var(--primaryLightAccentColor);
}

.buttonContent {
  width: 200px;
}

.icon {
  /* primary */
  fill: var(--primaryLightAccentColor);
}

.secondary {
  & .item {
    color: var(--secondaryLightAccentColor);
  }

  & .icon {
    fill: var(--secondaryLightAccentColor);
  }
}
