.createButtonLink {
  display: flex;
  width: 100%;
  height: 60px;

  margin-bottom: 16px;
  border-radius: 8px;
  font-size: 1.25rem;
  box-shadow: rgb(0 0 0 / 20%) 2px 4px 12px;

  & > div {
    white-space: normal;
  }
}

.createButtonLinkContent {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  white-space: normal;
  text-align: center;
}
