.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  border-radius: 2px;
  box-sizing: border-box;
  overflow: hidden;

  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  border-radius: 2px;
  /*
  color: white;
  */
  color: #0078ff;
  /*
  background-color: var(--blue-color-500);
  */
  background-color: #d8eaff;
  /*
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  */
  user-select: none;
}

.danger {
  /*
  background-color: var(--control-danger-color);
  */
  background-color: #f1a9a0;
  /*
  color: white;
  */
}

.large {
  font-size: 13px;
  padding: 8px 14p;
}

.content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
