:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  --headerBorderRadius: 8px;
  --headerHeight: 100px;
  --contentTopPadding: 40px;
  --nameFontSize: 1.1rem;

  /*--headerPadding: var(--contentInlinePadding);*/

  /* значение из StyledBlock */
  --styledBlockInlinePadding: 8px;
  --styledBlockBorderRadius: 8px;

  width: 100%;

  @media (min-width: __small) {
    --styledBlockInlinePadding: var(--contentInlinePadding);

    --headerHeight: 120px;
    --contentTopPadding: 50px;
    --nameFontSize: 1.2rem;
  }

  @media (min-width: __medium) {
    --styledBlockInlinePadding: 20px;
    --nameFontSize: 1.2rem;
  }
}

.styledBlock {
  padding-top: var(--styledBlockInlinePadding);
  /* padding-bottom: 20px; */
  padding-bottom: var(--styledBlockInlinePadding);

  & + & {
    margin-top: var(--styledBlockInlinePadding);
  }
}

.wrapper {
  position: relative;

  & + & {
    margin-top: 1rem;
  }
}

.background {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  height: var(--headerHeight);

  border-radius: var(--headerBorderRadius);

  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 75, 147, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );

  background: linear-gradient(
    99.25deg,
    #409cb5 30.84%,
    rgba(59, 201, 239, 0.49) 66.16%,
    rgba(141, 179, 189, 0.42) 98.66%
  );
}

.contentWrapper {
  position: relative;
  z-index: 15;
  padding-top: var(--contentTopPadding);

  @media (min-width: __small) {
    padding-left: 20px;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  /*
  position: relative;
  z-index: 15;
  padding-top: var(--contentTopPadding);
  */

  @media (min-width: __small) {
    flex-direction: row;
    align-items: flex-start;

    /*
    padding-left: 20px;
    */
  }
}

.avatarContainer {
  margin-bottom: 16px;

  @media (min-width: __small) {
    margin-right: 20px;
  }
}

.avatar {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.dataContainer {
  width: 100%;
  flex-grow: 1;

  @media (min-width: __small) {
    padding-top: 80px;
  }
}

.name {
  font-size: var(--nameFontSize);
  font-weight: 600;
  text-align: center;

  overflow-wrap: break-word;
  word-break: break-word;

  @media (min-width: __small) {
    text-align: unset;
  }
}

.chipContainer {
  margin-top: 12px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @media (min-width: __small) {
    align-items: flex-start;
  }
}

.blockedChip {
  margin-top: 8px;
}

.atributeContainer {
  margin-top: 16px;

  & > * {
    & + & {
      margin-top: 4px;
    }
  }
}

.actions {
  margin-top: 16px;
  width: 100%;
  text-align: right;
}

.actionsFlexContainer > div {
  justify-content: flex-end;
}

.resendHintContainer {
  padding: 16px var(--styledBlockInlinePadding);
  border-radius: var(--headerBorderRadius);
  background-color: #e4f9ed;

  display: flex;
  flex-flow: row nowrap;
}

.resendHintIcon {
  flex: 0 0 auto;
  margin-right: 8px;
  fill: var(--secondaryColor);
  width: 16px;
  height: 16px;
}

.resendHintText {
  flex: 1 1 auto;
  font-size: 0.9rem;
  line-height: 1.3;
}

.resendActions {
  margin-top: var(--styledBlockInlinePadding);
}
