.root {
  --border-radius: 8px;
}

.droppable {
  border-radius: var(--border-radius);
}

.internalRoot {
  --transition-duration: 0.8s;
  --border-color: #cecece;
  --background-color: white;
  --overlay-background-color: #fafbff;
  --icon-size: 60px;
  --icon-color: #bebebe;
  --hint-color: #bebebe;
  --hint-accent-color: #9e9e9e;
  --padding: 16px;

  position: relative;
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  border: 2px dashed var(--border-color);

  /*
  transition: border-color 0.8s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-duration: var(--transition-duration);
  */

  padding: var(--padding);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.rootDisabled {
  & .internalRoot {
    --background-color: #fbfafa;
    --border-color: var(--control-border-color);

    cursor: not-allowed;
  }
}

.internaalRootDanger {
  --border-color: darkred;
}

.internalRootActive {
  --border-color: var(--primary-color);
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 70%;

  z-index: 5;
}

.iconWrapper {
}

.icon {
  width: var(--icon-size);
  height: var(--icon-size);
  fill: var(--icon-color);

  /*
  transition: fill 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-duration: var(--transition-duration);
  */
}

.hint {
  color: var(--hint-color);
  font-size: 0.8rem;
  text-align: center;
  line-height: 1.3;
  font-weight: 600;

  margin-top: 8px;
}

.hintDelimiter {
  font-size: 0.8rem;
  margin: 4px 0;
  font-weight: initial;
}

.buttonBrowse {
  font-weight: 600;
}

.overlay {
  --icon-size: 90px;
  --icon-color: var(--primary-color);

  display: none;

  border-radius: var(--border-radius);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 10;
  background-color: var(--overlay-background-color);
}

.overlayWrapper {
  width: 100%;
  height: 100%;

  padding: var(--padding);

  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayActive {
  display: block;
}
