.root {
  /*
  background: rgb(0, 75, 147);
  */
  background: linear-gradient(
    90deg,
    rgba(0, 75, 147, 1) 35%,
    rgba(8, 79, 93, 1) 100%
  );

  /*
  &:active,
  &:focus,
  &:hover {
    background: linear-gradient(9deg, #1d874a, #004384) !important;
  }
  */
}
