:import('~src/styles/breakpoints.module.css') {
  __xsmall: xs;
  __small: s;
  __medium: md;
}

.background {
  background-image: linear-gradient(
    90deg,
    #eeeeee 0px,
    #f8f8f8 40px,
    #eeeeee 80px
  );
  background-size: 1400px;
}

.blueBackground {
  background-image: linear-gradient(
    90deg,
    #e5f1ff 0px,
    #f8f8f8 40px,
    #e5f1ff 80px
  );
  background-size: 1400px;
}

.root {
  --headerBorderRadius: 8px;
  --headerHeight: 80px;
  --contentTopPadding: 8px;

  --animationDuration: 4s;
  --animationStartPosition: -100px;
  --animationEndPosition: 600px;

  --lineMaxWidht: 200px;

  /*--headerPadding: var(--contentInlinePadding);*/

  /* значение из StyledBlock */
  --styledBlockInlinePadding: 8px;
  --styledBlockBorderRadius: 8px;

  width: 100%;

  @media (min-width: __xsmall) {
    --lineMaxWidht: 260px;
  }

  @media (min-width: __small) {
    --headerHeight: 100px;
    --contentTopPadding: 16px;

    --styledBlockInlinePadding: var(--contentInlinePadding);

    --animationDuration: 4s;
    --animationEndPosition: 1000px;
    --lineMaxWidht: unset;
  }

  @media (min-width: __medium) {
    --styledBlockInlinePadding: 20px;
  }
}

.styledBlock {
  padding-top: var(--styledBlockInlinePadding);
  padding-bottom: var(--styledBlockInlinePadding);

  box-shadow: unset;
}

.wrapper {
  position: relative;
}

.header {
  composes: blueBackground;
  height: var(--headerHeight);
  border-radius: var(--headerBorderRadius);

  animation: headerAnimation var(--animationDuration) infinite;
}

.contentWrapper {
  position: relative;
  z-index: 15;
  padding-top: var(--contentTopPadding);
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @media (min-width: __small) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.avatarContainer {
  margin-bottom: 16px;

  @media (min-width: __small) {
    margin-right: 20px;
  }
}

.avatar {
  composes: background;
  height: 40px;
  width: 120px;
  border-radius: 12px;

  animation: headerAnimation var(--animationDuration) infinite;
}

.dataContainer {
  width: 100%;
  flex-grow: 1;
}

.name {
  composes: background;
  height: 32px;
  max-width: var(--lineMaxWidht);
  border-radius: 4px;
  margin: 0 auto;

  animation: headerAnimation var(--animationDuration) infinite;

  @media (min-width: __small) {
    max-width: var(--lineMaxWidht);
    margin: unset;
  }
}

.attribute {
  composes: background;
  height: 20px;
  max-width: var(--lineMaxWidht);
  border-radius: 4px;
  margin: 0 auto;

  animation: headerAnimation var(--animationDuration) infinite;

  & + & {
    margin-top: 8px;
  }

  @media (min-width: __small) {
    max-width: var(--lineMaxWidht);
    margin: unset;
  }
}

.attributeList {
  margin-top: 12px;
}

@keyframes headerAnimation {
  0% {
    background-position: var(--animationStartPosition);
  }

  40%,
  100% {
    background-position: var(--animationEndPosition);
  }
}

@keyframes descriptionAnimation {
  0% {
    background-position: calc(
      var(--rlsiAnimationStartPosition) + var(--rliInlinePadding)
    );
  }

  40%,
  100% {
    background-position: calc(
      var(--rlsiAnimationEndPosition) - var(--rliInlinePadding)
    );
  }
}

@keyframes attributeKeyAnimation {
  0% {
    background-position: calc(
      var(--rlsiAnimationStartPosition) + var(--rliInlinePadding)
    );
  }

  40%,
  100% {
    background-position: calc(
      var(--rlsiAnimationEndPosition) - var(--rliInlinePadding)
    );
  }
}

@keyframes attributeValueAnimation {
  0% {
    background-position: calc(
      var(--rlsiAnimationStartPosition) + var(--rliInlinePadding) - 50px - 16px
    );
  }

  40%,
  100% {
    background-position: calc(
      var(--rlsiAnimationEndPosition) - var(--rliInlinePadding) - 50px - 16px
    );
  }
}
