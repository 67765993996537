.root {
  padding: 16px 18px;
}

.buttons {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  & + & {
    margin-left: 12px;
  }
}
