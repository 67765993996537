.icon {
  width: 14px;
  height: 14px;
  fill: #a92626;
}

.label {
  color: #a92626;
  margin-left: 4px;
}
