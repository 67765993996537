:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  --pIconHeight: 160px;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (min-width: __small) {
    --pIconHeight: 200px;
  }

  @media (min-width: __medium) {
    --pIconHeight: 240px;
  }
}

.content {
  max-width: 360px;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.iconWrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  height: var(--pIconHeight);
  max-height: var(--pIconHeight);
  overflow: hidden;
}

.title {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 16px;
  color: black;
  line-height: 1.2;

  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: __medium) {
    font-size: 1.25rem;
    margin-bottom: 12px;
  }
}

.danger {
  & .title {
    color: darkred;
  }
}

.description {
  margin-top: 8px;
  text-align: center;
  font-size: 0.9rem;
  color: black;
  font-weight: 400;
  line-height: 1.2;

  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: __medium) {
    font-size: 1rem;
  }
}

.appendix {
  margin-top: 8px;
  line-height: 1.2;

  overflow: hidden;
  text-overflow: ellipsis;
}
