.enter {
  opacity: 0;
  transform: translateY(-20px);
}

.enterDown {
  opacity: 0;
  transform: translateY(20px);
}

.enterActive,
.enterActiveDown {
  opacity: 1;
  transform: unset;
  transition: opacity 200ms, transform 200ms;
}

.exit,
.exitDown {
  opacity: 1;
  transform: unset;
}

.exitActive {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 200ms, transform 200ms;
}

.exitActiveDown {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 200ms, transform 200ms;
}

.exitDone,
.exitDoneDown {
  opacity: 0;
}
