.root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  background-color: #f8fbff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  flex: 0 0 auto;
}
