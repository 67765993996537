.root {
  min-height: 400px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  & > * {
    flex-grow: 1;
  }
}

.content {
}
