.root {
  --rc-dot-size: 10px;
  --rc-font-size: 0.9rem;

  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
}

.dotWrapper {
  flex: 0 0 auto;
  margin-right: 6px;
  line-height: 1;
}

.dot {
  border-radius: 50%;
  width: var(--rc-dot-size);
  height: var(--rc-dot-size);
  background-color: #000000;
}

.label {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: var(--rc-font-size);
  line-height: 1;
  font-weight: 400;
}

.rootSmall {
  --rc-dot-size: 9px;
  --rc-font-size: 0.8rem;
}
