:import('~src/styles/breakpoints.module.css') {
  small: s;
  medium: md;
}

.root {
  color: #191d23;
  display: inline-flex;
  align-items: center;
  border-radius: 0px;
  padding: 6px 6px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  text-decoration: unset;
  transition: color 0.12s ease;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: medium) {
    font-size: 1.2rem;
    padding-left: 12px;
    padding-right: 12px;
  }

  &:hover {
    color: var(--primary-color);
  }

  &:visited {
    text-decoration: unset;
  }
}
