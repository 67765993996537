.root {
  font-size: 1rem;
  padding: 0 0.75em;
  /*
  background-color: #daeeff;

  &:hover,
  &:active,
  &:focus {
    background-color: #cfe2f2;
  }
  */
}
