.root {
}

.hint {
  color: #828282;
  font-size: 11px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 2px;
}

.link {
  line-height: 1;
}
