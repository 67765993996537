:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

:root {
  --headerHeight: 48px;

  @media screen and (min-width: __medium) {
    --headerHeight: 58px;
  }
}

.root {
  width: 100%;
  height: var(--headerHeight);
  max-height: var(--headerHeight);
  position: relative;
  background-color: inherit;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  box-sizing: border-box;
  height: var(--headerHeight);
  max-height: var(--headerHeight);
}
