:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  position: relative;
}

.buttons {
  margin-top: 16px;
}

.button {
  width: 100%;
  background: linear-gradient(
    9deg,
    var(--secondaryColor),
    var(--primary-color)
  ) !important;

  &:active,
  &:focus,
  &:hover {
    background: linear-gradient(9deg, #1d874a, #004384) !important;
  }
}
