.root {
  --checkbox-input-color: var(--primary-color);
  --checkbox-size: 22px;

  margin: 0;
  padding: 0;

  & svg {
    width: var(--checkbox-size);
    height: var(--checkbox-size);
  }
}

.input {
  padding: 0;
  color: rgba(0, 0, 0, 0.8);
  /*
  color: var(--checkbox-input-color);
  */

  &.inputChecked {
    color: var(--checkbox-input-color);
  }
}

.label {
  margin-left: 4px !important;
  font-family: unset !important;
}

.rootSecondary {
  --checkbox-input-color: var(--secondaryColor);
}

.rootSmall {
  --checkbox-size: 16px;
}
