.value {
  display: block;

  overflow-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rootNoWrap {
  & .value {
    white-space: nowrap;
  }
}
