.root {
}

.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.spinner {
  margin-right: 8px;
  flex: 0 0 auto;
}
