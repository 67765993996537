:import('~src/styles/breakpoints.module.css') {
  s: s;
  medium: md;
}

.div {
  display: block;
}

.h1 {
  font-size: 1.75rem;
  margin: 2rem 0;
  font-weight: 500;
  text-align: center;

  @media (min-width: medium) {
    margin: 56px 0;
    font-size: 2rem;
  }

  &.small {
    margin: 2rem 0;
    font-size: 1.25rem;
    text-align: left;
    font-weight: 600;

    @media screen and (min-width: s) {
      font-size: 1.5rem;
    }
  }
}

.h2 {
  margin: 2rem 0;

  &.small {
    margin: 1.25rem 0;
    font-size: 1.15rem;
  }
}

.p {
  line-height: 1.2;

  &.small {
    font-size: 0.9rem;
  }
}
