.root {
  --raidIconContainerSize: 24px;
  --raidIconSize: 16px;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.rootLarge {
  --raidIconSize: 24px;
}

.rootSmall {
  --raidIconSize: 12px;
  --raidIconContainerSize: 20px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  width: var(--raidIconContainerSize);
  height: var(--raidIconContainerSize);
  border-radius: 4px;
  background-color: #ecf6ff;
}

.icon {
  fill: var(--secondaryColor);

  width: var(--raidIconSize);
  height: var(--raidIconSize);
}

.text {
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 1;
}
