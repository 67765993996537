@import 'styles/sizes.css';
@import 'styles/colors.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,700&display=swap');

:root {
  --primary-accent-color: #1976d2;
  --primary-color: #004b93;
  --bg-color: #f5f5f5;
  --error-color: #ff370c;
  --success-color: #2fa55b;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100%;
  background-color: #f8fbff;
  color: #000000;
}

#root {
  height: 100%;
}
