:import('~src/styles/breakpoints.module.css') {
  __xsmall: xs;
  __small: s;
  __medium: md;
}

.root {
  --dialogBlockMargin: 48px;
  --dialogInlineMargin: 24px;

  width: auto;

  & :global(.MuiBackdrop-root) {
    backdrop-filter: blur(5px);
  }

  & :global(.MuiPaper-root) {
    backdrop-filter: blur(5px);
    margin: var(--dialogBlockMargin) var(--dialogInlineMargin);
  }

  @media (min-width: __xsmall) {
    --dialogInlineMargin: 32px;
  }

  @media (min-width: __small) {
    --dialogInlineMargin: 48px;
  }

  @media (min-width: __medium) {
    --dialogInlineMargin: 60px;
  }
}
