.wrapper {
  display: flex;
  align-items: center;
}

.icon {
  width: 12px;
  height: 12px;
  flex: 0 0 auto;

  fill: #0078ff;
}

.label {
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}
