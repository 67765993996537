.actions {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  margin-top: 16px;
}

.actionButton {
  flex-shrink: 1;

  & + & {
    margin-left: 8px;
  }
}
