.root {
}

.disabled {
}

.contained {
  &.primary {
    /*
    color: var(--control-primary-accent-color);

    &:hover {
      background-color: var(--control-primary-color-hover);
    }

    &:active {
      background-color: var(--control-primary-color-active);
    }
    */

    &.disabled,
    &:disabled {
      &,
      &:hover,
      &:active {
        background-color: var(--control-primary-color-disabled);
      }
    }
  }

  &.danger {
    /*
    color: var(--control-danger-accent-color);

    &:hover {
      background-color: var(--control-danger-color-hover);
    }

    &:active {
      background-color: var(--control-danger-color-active);
    }
    */

    &.disabled,
    &:disabled {
      &,
      &:hover,
      &:active {
        background-color: var(--control-danger-color-disabled);
      }
    }
  }

  &.secondary {
    /*
    background-color: var(--secondaryColor);
    color: var(--secondaryAccentColor);

    &:hover {
      background-color: var(--secondaryHoverColor);
    }

    &:active,
    &:focus {
      background-color: var(--secondaryActiveColor);
    }
    */

    &.disabled,
    &:disabled {
      &,
      &:hover,
      &:active {
        background-color: var(--secondaryDisabledColor);
      }
    }
  }
}

.outlined {
  &.primary {
    /*
    color: var(--control-primary-color);

    &:hover {
      color: var(--control-primary-color-hover);
    }

    &:active {
      color: var(--control-primary-color-active);
    }
    */
    &.disabled,
    &:disabled {
      &,
      &:hover,
      &:active {
        color: var(--control-primary-color-disabled);
        border-color: var(--control-primary-color-disabled);
      }
    }
  }

  &.danger {
    /*
    color: var(--control-danger-color);

    &:hover {
      color: var(--control-danger-color-hover);
    }

    &:active {
      color: var(--control-danger-color-active);
    }
    */
  }
}

.text {
  &.primary {
    &.disabled,
    &:disabled {
      &,
      &:hover,
      &:active {
        color: var(--primaryDisabledColor);

        & svg {
          fill: var(--primaryDisabledColor);
        }
      }
    }
  }

  &.secondary {
    &.disabled,
    &:disabled {
      &,
      &:hover,
      &:active {
        color: var(--secondaryDisabledColor);

        & svg {
          fill: var(--secondaryDisabledColor);
        }
      }
    }
  }
}
