.root {
  --rcChipMargin: 4px;
  --rcChipMarginNegative: calc(-1 * var(--rcChipMargin));

  display: block;
}

.containerWrapper {
  margin-right: var(--rcChipMarginNegative);
  margin-bottom: var(--rcChipMarginNegative);
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  & > * {
    margin-right: var(--rcChipMargin);
    margin-bottom: var(--rcChipMargin);
  }
}
