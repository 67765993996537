:import('~src/styles/breakpoints.module.css') {
  small: s;
  medium: md;
  large: lg;
}

:root {
  --maxContentWidth: 900px;
  --maxContentSmallWidth: 700px;
  --minContentWidth: 320px;

  --contentVerticalPadding: 32px;

  /* отступ контента от края экрана*/
  /* --contentInlinePadding: 8px; */
  --contentInlinePadding: 12px;

  @media screen and (min-width: small) {
    --contentInlinePadding: 12px;
  }

  @media screen and (min-width: large) {
    --contentInlinePadding: 16px;
  }
}
