.root {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  z-index: 10;

  padding-top: 360px;
  overflow: hidden;
  background-color: #f8fbff;
  line-height: 0;

  /*
  background: url('~src/assets/svg/background.svg') no-repeat center top 360px /
    100% auto;
  */
}
