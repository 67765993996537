:import('~src/styles/breakpoints.module.css') {
  __large: l;
}

.root {
  max-width: var(--maxContentWidth);
  margin-left: auto;
  margin-right: auto;

  position: relative;
  display: flex;
}

.rootSmall {
  max-width: var(--maxContentSmallWidth);
}

.content {
  flex: 1 1 auto;
  min-width: 0;
  /*
  display: flex;
  flex-direction: column;
  */
  margin-right: var(--contentInlinePadding);
  margin-left: var(--contentInlinePadding);
}
