:import('~src/styles/common.module.css') {
  __primaryBoxShadow: primaryBoxShadow;
}

:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  --pButtonSize: 22px;
  --pIconSize: 16px;
  --pColor: var(--primary-color);
  --pColorDisabled: var(--control-primary-color-disabled);
  --pBackgroundColor: var(--primary-color);

  /*
  --pColor: #4f4f4f;
  --pBackgroundColor: #92c0e8;
  */

  position: relative;
  width: 100%;
  height: 45px;
  padding: 12px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.rootSecondary {
  --pColor: var(--secondaryColor);
  --pColorDisabled: var(--secondaryDisabledColor);
  --pBackgroundColor: var(--secondaryColor);
}

.layout {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

/*
TODO
почему-то эти стили инжектятся ПОСЛЕ стилей для кнопок
*/
.layoutItem {
  & + & {
    margin-left: 3px;
  }
}

.layoutIcon {
  width: var(--pIconSize) !important;
  height: var(--pIconSize) !important;
  fill: var(--pColor);
  flex: 0 0 auto;
}

.layoutChevronIcon {
  composes: layoutIcon;

  width: 6px !important;
  height: 10px !important;
}

.layoutDotsIcon {
  composes: layoutIcon;

  width: 12px !important;
  height: 12px !important;

  @media (min-width: 420px) {
    width: var(--pButtonSize) !important;
    height: var(--pButtonSize) !important;
  }
}

.layoutButton {
  min-width: var(--pButtonSize) !important;
  height: var(--pButtonSize) !important;
  line-height: var(--pButtonSize) !important;
  padding: 0 !important;
  border: 1px solid var(--pBackgroundColor) !important;
  background-color: white;
  color: var(--pColor);
  font-size: 14px !important;

  &:hover,
  &:focus,
  &:active {
    color: white;
    background-color: var(--pBackgroundColor);

    & .layoutIcon {
      fill: white;
    }
  }
}

.layoutButtonActive {
  color: white !important;
  background-color: var(--pBackgroundColor) !important;

  & .layoutIcon {
    fill: white;
  }
}

.layoutButtonDisabled {
  cursor: not-allowed;
  border-color: var(--pColorDisabled) !important;

  &,
  &:disabled,
  &:hover,
  &:focus,
  &:active {
    color: var(--pColorDisabled);
    background-color: white;

    & .layoutIcon {
      fill: var(--pColorDisabled);
    }
  }
}

.dots {
  display: flex;
  align-items: center;
}

.total {
  font-size: 14px;
  font-weight: 400;
  color: var(--pColor);
}
