.root {
  text-decoration: unset;

  &:visited {
    text-decoration: unset;
  }

  &.menuItem {
    display: block;
    width: 100%;
    padding: 6px 16px;
  }
}
