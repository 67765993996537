:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  --slib-inline-padding: 12px;
  --slib-block-padding: 8px;
  --slib-border-radius: 8px;
  --slib-border-color: transparent;

  position: relative;
  display: block;
  padding-top: var(--slib-block-padding);
  padding-bottom: var(--slib-block-padding);
  border: 2px solid var(--slib-border-color);
  border-radius: var(--slib-border-radius);
  background-color: white;
  background: #f9fcff;
  font-size: 1rem;

  @media (min-width: __medium) {
    --slib-block-padding: 8px;
  }
}

.accented {
  --slib-border-color: var(--primary-color);

  &.secondary {
    --slib-border-color: var(--secondaryColor);
  }
}
