:export {
  blockPadding: 8px;
  inlinePadding: var(--contentInlinePadding);
  borderRadius: 4px;
}

:root {
  --rliBlockPadding: 12px;
  --rliInlinePadding: var(--contentInlinePadding);
  --rliBorderRadius: 4px;
  --rliHeaderHeight: 40px;
}
