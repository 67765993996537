:import('~src/styles/breakpoints.module.css') {
  small: s;
}

.root {
  width: 220px;
  padding: 8px 16px;
  border-bottom: 2px solid #00000020;

  @media screen and (min-width: small) {
    width: 260px;
  }
}

.avatarContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 16px;
}

.name {
  margin: 8px 0;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.roleContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 1;
}
