.root {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
}

.link {
  font-size: 1rem;
  font-weight: 500;
  display: inline;
  white-space: normal;
  overflow-wrap: break-word;
  /* word-break: break-all; */
  word-break: normal;
  color: #007bff;
  text-decoration: unset;

  &:visited {
    text-decoration: unset;
    color: #007bff;
  }

  &:hover {
    text-decoration: underline;
    color: #007bff;
  }

  &:active {
    color: #007bff;
  }
}

.small {
  & .link {
    font-size: 0.9rem;
  }
}

.smaller {
  & .link {
    font-size: 0.8rem;
  }
}

.noWrap {
  display: block;

  & .link {
    white-space: nowrap;
  }
}
