.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  display: none;
  visibility: hidden;
}
