.root {
}

.label {
  color: #d93025;
  min-width: 0;
  flex: 1 1 auto;
  line-height: 1.25;
  font-size: 14px;
}

.detail {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  overflow: hidden;
  max-height: 70px;
  font-size: 0.9em;
  overflow-wrap: anywhere;
}
