.root {
  --result-icon-size: 80px;
}

.form {
  margin-top: 32px;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;

  line-height: 1;
}

.toolbarBlock {
  flex: 0 0 auto;
  line-height: 1;

  & + & {
    margin-left: 8px;
  }

  &.flex {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

.button {
  width: 100%;
  background: linear-gradient(
    9deg,
    var(--secondaryColor),
    var(--primary-color)
  ) !important;

  &:active,
  &:focus,
  &:hover {
    background: linear-gradient(9deg, #1d874a, #004384) !important;
  }
}

.resultSection {
  background-color: var(--secondaryColor);
  background: linear-gradient(9deg, #1d874a, #004384) !important;
}

.iconBlock {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  margin-bottom: 20px;
}

.resultIcon {
  width: var(--result-icon-size);
  height: var(--result-icon-size);

  fill: var(--secondaryAccentColor);
}

.resultText {
  color: white;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  overflow-wrap: anywhere;
}

.resultEmail {
  font-weight: 600;
  text-align: center;
  margin: 2px 0;
}

.linkBlock {
  margin-top: 30px;
  text-align: center;
}

.link a {
  color: white;
}
