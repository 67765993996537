.count {
  display: block;
  font-size: 0.9rem;
  line-height: 1.25;

  overflow-wrap: break-word;
  word-break: break-word;
}

.small {
  font-size: 0.8rem;
}
