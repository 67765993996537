:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  --a-label-color: #183d77;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-weight: 600;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: white;
  background-color: #daeeff;
  /*
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px,
    rgb(0 0 0 / 30%) 0px 1px 3px -1px;
*/
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 18px;

  @media (min-width: __medium) {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
  }
}

.withExtracted {
  padding-left: 4px;
  padding-right: 4px;
}

.small {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
}

.large {
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-size: 28px;
}

.larger {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 50px;
}

.extraLarge {
  width: 120px;
  height: 120px;
  line-height: 120px;
  font-size: 60px;
}

.img {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
}

.extracted {
  overflow: hidden;
  min-width: 0;
  white-space: nowrap;
  color: var(--a-label-color);
  text-transform: uppercase;
}

.rootPrimary {
  --a-label-color: var(--primary-color);
}

.rootSecondary {
  --a-label-color: var(--secondaryColor);
}

.fallback {
  width: 65%;
  height: 65%;
  fill: white;
}

.rounded {
  border-radius: 10px;
}
