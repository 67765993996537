.root {
  display: inline-block;
  line-height: 1;
}

.button {
  margin: 0;
  border: unset;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
  background-color: transparent;
}

.linkMenuItem {
  padding: 0;
}

.menuItemIcon {
  fill: var(--primary-color);
}
