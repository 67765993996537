.root {
  margin: 0;
  display: block;
  position: relative;

  font-size: 0.8rem;
  color: var(--gray-color-600);
  line-height: 1.5;

  overflow-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
