.root {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  border: 1px solid transparent;
}

.wrapper {
}

.uploadContainer {
  width: 100%;
  margin-bottom: 16px;
}

.avatarContainer {
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
