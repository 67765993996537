.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}

.icon {
  display: inline-block;
  flex: 0 0 auto;
  width: 16px;
  height: 16px;
}

.label {
  display: inline-block;
  margin-left: 8px;

  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noIcon {
  & .label {
    margin-left: 0;
  }
}

.noIcon.keepIconSpace {
  & .label {
    margin-left: 24px;
  }
}
