@import url('../vars.css');

.background {
  background-image: linear-gradient(
    90deg,
    #eeeeee 0px,
    #f8f8f8 40px,
    #eeeeee 80px
  );
  background-size: 1400px;
}

.blueBackground {
  background-image: linear-gradient(
    90deg,
    #e5f1ff 0px,
    #f8f8f8 40px,
    #e5f1ff 80px
  );
  background-size: 1400px;
}

.root {
  --borderRaduis: 4px;
  --avatarInlineMargin: 12px;
  --avatarSize: 40px;

  --animationDuration: 4s;
  --animationStartPosition: -100px;
  --animationEndPosition: 900px;

  background-color: white;
  box-shadow: unset;
  /*
  padding-top: 8px;
  padding-bottom: 8px;
  */

  display: flex;
  align-items: flex-start;
}

.avatar {
  composes: background;

  flex: 0 0 auto;
  border-radius: var(--borderRaduis);
  width: var(--avatarSize);
  height: var(--avatarSize);
  margin-right: 12px;

  animation: avatarAnimation var(--animationDuration) infinite;
}

.header {
  composes: blueBackground;

  height: 20px;
  border-radius: var(--borderRaduis);

  animation: headerAnimation var(--animationDuration) infinite;
}

.attributes {
  composes: background;

  height: 12px;
  margin-top: 8px;
  border-radius: var(--borderRaduis);

  animation: headerAnimation var(--animationDuration) infinite;
}

.content {
  flex: 1 1 auto;
}

@keyframes headerAnimation {
  0% {
    background-position: calc(
      var(--animationStartPosition) - var(--avatarSize) -
        var(--avatarInlineMargin)
    );
  }

  40%,
  100% {
    background-position: calc(
      var(--animationEndPosition) - var(--avatarSize) -
        var(--avatarInlineMargin)
    );
  }
}

@keyframes avatarAnimation {
  0% {
    background-position: var(--animationStartPosition);
  }

  40%,
  100% {
    background-position: var(--animationEndPosition);
  }
}
