.descriptionBlock {
  font-size: 1rem;
  text-align: center;
  line-height: 1.3;
  padding: 0;
  margin: 0;
}

.hintBlock {
  font-size: 0.8rem;
  color: var(--gray-color-600);
  line-height: 1.2;

  overflow-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.errorBlock {
  margin-top: 8px;
}

.inputRoot {
  width: 100%;
}

.input {
  border-color: #4f4f4f;
  background-color: #f8fbff;
}

.inputIcon {
  fill: #4f4f4f;
}

.submitButton {
  width: 100%;
  text-transform: uppercase;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.link {
  line-height: 1.25;
}
