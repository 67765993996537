@use 'sass:map';

$white: #fff !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$gray: #bab1ad !default;
$gray-dark: #403734 !default;

$color-bases: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  //red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  gray: $gray,
  gray-dark: $gray-dark,
);

$color-variants: (
  0: -3%,
  100: -6%,
  200: -20%,
  300: -50%,
  400: -75%,
  500: 100%,
  600: 75%,
  700: 50%,
  800: 25%,
  900: 15%,
);

$colors: () !default;

@each $color-name, $color in $color-bases {
  @each $variant-name, $percent in $color-variants {
    $mix-color: if($percent < 0%, white, black);
    $colors: map.merge(
      $colors,
      (
        #{$color-name}-color-#{$variant-name}:
          mix($color, $mix-color, abs($percent))
      )
    );
  }
}

:root {
  @each $color-name, $color in $colors {
    --#{$color-name}: #{$color};
  }
}
