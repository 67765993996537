.dangerAction {
  color: red;
}

.icon {
  display: inline-flex;
  align-items: center;
}

.dangerIcon {
  fill: red;
}
