.root {
  --active-color: rgba(0, 0, 0, 0.04);

  padding: 6px 16px;
  font-family: unset;

  &:hover {
    background-color: var(--active-color);
  }

  &.selected {
    background-color: var(--active-color);
  }
}
