:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  position: relative;
}

.wrapper {
  padding: 16px 0;
}

.buttons {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.button {
  & + & {
    margin-left: 8px;
  }
}
