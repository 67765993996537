.root {
  min-height: 400px;
}

.content {
}

.pagination {
  margin-top: 16px;
}
