.root {
  display: block;
  position: relative;
  z-index: 10;
  margin: 0;

  & + & {
    margin-top: 16px;
  }
}

.rootWithValidation {
  margin-bottom: 32px;
}

.label {
  margin-bottom: 4px;
  font-size: 1rem;
  line-height: 1.3;
  color: var(--text-color);
  /* color: var(--gray-color-600); */
  font-weight: 600;
}

.labelRequired {
  &:after {
    content: '*';
    display: inline-block;
    vertical-align: top;
    user-select: none;
    margin-left: 2px;
    color: red;
  }
}

.hint {
  margin: 12px 0 16px;
}

.input {
  display: block;
  position: relative;
}

.inputWrapper {
  position: relative;
  z-index: 15;
}

.errorBlock {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 10;
  overflow: hidden;
}
