.root {
  --button-like-height-smaller: 24px;
  --button-like-height-small: 32px;
  --button-like-height-medium: 40px;
  --button-like-height-large: 46px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: var(--button-like-height-medium);
  line-height: var(--button-like-height-medium);
  background-color: unset;
  color: var(--text-color);
  box-sizing: border-box;
  outline: 0;

  border: unset;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  padding: 0 1em;

  transition: background-color 0.15s cubic-bezier(0.165, 0.84, 0.44, 1),
    color 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);

  text-decoration: unset;
}

.uppercased {
  text-transform: uppercase;
}

.smaller {
  height: var(--button-like-height-smaller);
  line-height: var(--button-like-height-smaller);
}

.small {
  height: var(--button-like-height-small);
  line-height: var(--button-like-height-small);
  font-size: 0.9rem;
  padding: 0 0.75em;
}

.large {
  height: var(--button-like-height-large);
  line-height: var(--button-like-height-large);
}

.wrapper {
  display: block;
  /*
  line-height: 1;
  */
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconed {
  padding: 0 0.75em;
}

.icon {
  width: 16px;
  height: 16px;
  flex: 0 0 auto;
}

.hasPrefix {
  & .wrapper {
    margin-left: 8px;
  }

  &.small {
    & .wrapper {
      margin-left: 4px;
    }
  }
}

.hasSuffix {
  & .wrapper {
    margin-right: 8px;
  }
}

.disabled {
  cursor: not-allowed;
}

.contained {
  &.primary {
    background-color: var(--control-primary-color);
    color: var(--control-primary-accent-color);

    &:hover {
      background-color: var(--control-primary-color-hover);
    }

    &:active,
    &:focus {
      background-color: var(--control-primary-color-active);
    }
  }

  &.primaryLight {
    background-color: var(--primaryLightColor);
    color: var(--primaryLightAccentColor);

    &:hover {
      background-color: var(--primaryLightHoverColor);
    }

    &:active,
    &:focus {
      background-color: var(--primaryLightActiveColor);
    }
  }

  &.danger {
    background-color: var(--control-danger-color);
    color: var(--control-danger-accent-color);

    &:hover {
      background-color: var(--control-danger-color-hover);
    }

    &:active,
    &:focus {
      background-color: var(--control-danger-color-active);
    }
  }

  &.secondary {
    background-color: var(--secondaryColor);
    color: var(--secondaryAccentColor);

    &:hover {
      background-color: var(--secondaryHoverColor);
    }

    &:active,
    &:focus {
      background-color: var(--secondaryActiveColor);
    }
  }

  &.secondaryLight {
    background-color: var(--secondaryLightColor);
    color: var(--secondaryLightAccentColor);

    &:hover {
      background-color: var(--secondaryLightHoverColor);
    }

    &:active,
    &:focus {
      background-color: var(--secondaryLightActiveColor);
    }
  }

  & .icon {
    fill: var(--control-primary-accent-color);
  }
}

.outlined {
  border-width: 1px;
  border-style: solid;
  background-color: unset;
  border-color: var(--control-primary-color);
  color: var(--control-primary-color);

  &.primary {
    border-color: var(--control-primary-color);
    color: var(--control-primary-color);

    &:hover {
      border-color: var(--control-primary-color-hover);
      color: var(--control-primary-color-hover);
    }

    &:active,
    &:focus {
      border-color: var(--control-primary-color-active);
      color: var(--control-primary-color-active);
    }

    &.disabled {
      &,
      &:hover,
      &:active {
        border-color: var(--control-primary-color-disabled);
        color: var(--control-primary-color-disabled);
      }
    }
  }

  &.secondary {
    border-color: var(--secondaryColor);
    color: var(--secondaryColor);

    &:hover {
      border-color: var(--secondaryHoverColor);
      color: var(--secondaryHoverColor);
    }

    &:active,
    &:focus {
      border-color: var(--secondaryActiveColor);
      color: var(--secondaryActiveColor);
    }

    &.disabled {
      &,
      &:hover,
      &:active {
        border-color: var(--secondaryDisabledColor);
        color: var(--secondaryDisabledColor);
      }
    }
  }

  &.danger {
    border-color: var(--control-danger-color);
    color: var(--control-danger-color);

    &:hover {
      border-color: var(--control-danger-color-hover);
      color: var(--control-danger-color-hover);
    }

    &:active {
      border-color: var(--control-danger-color-active);
      color: var(--control-danger-color-active);
    }

    &.disabled {
      &,
      &:hover,
      &:active {
        border-color: var(--control-danger-color-disabled);
        color: var(--control-danger-color-disabled);
      }
    }
  }
}

.text {
  border-width: 0;
  border-color: unset;
  background-color: unset;
  padding: 0 0.5em;

  &.iconed {
    padding-left: 0.35em;
    padding-right: 0.35em;
  }

  &.primary {
    color: var(--control-primary-color);

    & .icon {
      fill: var(--control-primary-color);
    }

    &:hover {
      color: var(--control-primary-color-hover);

      & .icon {
        fill: var(--control-primary-color-hover);
      }
    }

    &:active,
    &:focus {
      color: var(--control-primary-color-active);

      & .icon {
        fill: var(--control-primary-color-active);
      }
    }

    &.disabled {
      &,
      &:hover,
      &:active {
        color: var(--blue-color-200);

        & .icon {
          fill: var(--blue-color-200);
        }
      }
    }
  }

  &.secondary {
    color: var(--secondaryColor);

    & .icon {
      fill: var(--secondaryColor);
    }

    &:hover {
      color: var(--secondaryHoverColor);

      & .icon {
        fill: var(--secondaryHoverColor);
      }
    }

    &:active,
    &:focus {
      color: var(--secondaryActiveColor);

      & .icon {
        fill: var(--secondaryActiveColor);
      }
    }

    &.disabled {
      &,
      &:hover,
      &:active {
        color: var(--secondaryDisabledColor);

        & .icon {
          fill: var(--secondaryDisabledColor);
        }
      }
    }
  }

  &.danger {
    color: var(--control-danger-color);

    & .icon {
      fill: var(--control-danger-color);
    }

    &:hover {
      color: var(--control-danger-color-hover);
      & .icon {
        fill: var(--control-danger-color-hover);
      }
    }

    &:active,
    &:focus {
      color: var(--control-danger-color-active);
      & .icon {
        fill: var(--control-danger-color-active);
      }
    }

    &.disabled {
      &,
      &:hover,
      &:active {
        color: var(--control-danger-color-disabled);

        & .icon {
          fill: var(--control-danger-color-disabled);
        }
      }
    }
  }
}

.uppercased {
  text-transform: uppercase;
}
