.root {
  line-height: 1;
}

.item {
  color: #004b93;
}

.buttonContent {
  width: 200px;
}

.icon {
  fill: #004b93;
}
