:import('~src/styles/breakpoints.module.css') {
  small: s;
  medium: md;
}

:root {
  --headerHeight: 48px;

  @media screen and (min-width: medium) {
    --headerHeight: 58px;
  }
}

.root {
  width: 100%;
  height: var(--headerHeight);
  max-height: var(--headerHeight);
  background-color: #ffffff;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  height: var(--headerHeight);
  max-height: var(--headerHeight);
}

.logoContainer {
  margin-right: 12px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  @media screen and (min-width: small) {
    margin-right: 24px;
  }
}

.logoLink {
  overflow: hidden;
  position: relative;
  display: block;
}

.logo {
  --headerSmallLogoHeight: 36px;
  --logo-size: 36px;

  display: inline-block;
  vertical-align: middle;
  object-fit: cover;

  &.logoSmall {
    display: inline-block;
    width: 40px;
    height: 40px;

    @media screen and (min-width: small) {
      display: none;
    }
  }

  &.logoLarge {
    display: none;
    max-width: 150px;
    height: 32px;

    @media screen and (min-width: small) {
      display: inline-block;
    }
  }
}

.nav {
  height: var(--headerHeight);
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  min-width: 0;
  overflow-x: auto;

  @media screen and (min-width: small) {
    margin-right: 24px;
  }
}

.navLink {
  flex: 1 0 auto;

  @media (min-width: medium) {
    height: 100%;
  }
}

.locale {
  flex: 0 0 auto;
  margin-left: 16px;
}

.profileButton {
  flex: 0 0 auto;
  margin-left: 8px;
}
