:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  --sb-inline-padding: 8px;
  --sb-block-padding: 16px;
  --sb-border-radius: 8px;

  position: relative;
  display: block;
  padding: var(--sb-block-padding) var(--sb-inline-padding);
  border-radius: var(--sb-border-radius);
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;

  @media (min-width: __small) {
    --sb-inline-padding: var(--contentInlinePadding);
  }

  @media (min-width: __medium) {
    --sb-inline-padding: 20px;
  }
}
