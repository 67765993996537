.items {
  margin: 0;
}

.block {
  & + & {
    margin-top: 16px;
  }
}

.blockTitle {
  margin-bottom: 8px;
  font-size: 0.9rem;
}

.formItem {
  & + & {
    margin-top: 2px;
  }
}
