:import('~src/styles/breakpoints.module.css') {
  __xsmall: xs;
  __small: s;
  __medium: md;
}

:import('~src/styles/common.module.css') {
  __styledBlockShadow: styledBlockShadow;
  __styledBlockBorderRadius: styledBlockBorderRadius;
}

.root {
  --inline-padding: 24px;
  --block-padding: 28px;

  position: relative;
  display: block;
  padding: var(--block-padding) var(--inline-padding);
  border-radius: __styledBlockBorderRadius;
  background-color: white;
  box-shadow: __styledBlockShadow;

  /*
  @media (min-width: __xsmall) {
    --inline-padding: 24px;
  }
  */

  @media (min-width: 420px) {
    --inline-padding: 36px;
  }

  @media (min-width: __small) {
    --inline-padding: 36px;
  }
}
