.container label {
  position: absolute;
  top: 50%;
}

.selectRoot {
  height: 40px;

  & fieldset {
    border: 1px solid !important;
    border-color: var(--control-border-color) !important;
  }

  &:hover {
    & fieldset {
      border-color: var(--control-primary-color-hover) !important;
    }
  }

  &:active,
  &:global(.Mui-focused),
  &:focus {
    & fieldset {
      border-color: var(--control-primary-color-active) !important;
    }
  }
}

.select {
  color: #191d23;
  font-family: 'Manrope' !important;

  display: flex;
  height: 100% !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.blank {
  & .selectRoot fieldset {
    border: none !important;
  }
}

.small {
  & .selectRoot {
    height: 32px;
  }
}

.large {
  & .selectRoot {
    height: 46px;
  }
}
