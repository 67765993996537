/*
TODO
поправить классы, чтобы они корректно определялись с минимальной специфичностью
*/

.root {
  position: relative;
  height: 40px;
  line-height: 40px;
  display: inline-block;

  &.hasPrefix {
    & .input {
      padding-left: 36px;
    }
  }

  &.hasSuffix {
    & .input {
      padding-right: 36px;
    }
  }

  &.small {
    height: 32px;
  }

  &.large {
    height: 46px;
  }
}

.appendix {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;

  position: absolute;
  height: 100%;
  top: 0;
  text-align: center;
  transition: all 0.3s;
  pointer-events: none;
  overflow: hidden;
}

.prefix {
  left: 8px;
}

.suffix {
  right: 8px;
}

.icon {
  fill: #828282;
  width: 20px;
  height: 20px;
}

.input {
  width: 100%;
  height: 100%;
  line-height: inherit;
  background-color: #fff;
  color: black;
  font-size: 1rem;
  font-weight: 400;
  font-family: unset;
  outline: 0;
  border: 1px solid;
  border-radius: 4px;
  border-color: var(--control-border-color);
  padding: 0 12px;
  transition: border-color 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

  &::placeholder {
    color: #bab1ad;
  }

  &[type='search'] {
    appearance: none;
  }

  @nest .root.large & {
    padding: 0 16px;
    border-width: 2px;
  }

  @nest .root.small & {
    font-size: 0.9rem;
    padding: 0 8px;
  }

  &:hover {
    border-color: var(--control-primary-color-hover);
  }

  &:active,
  &:focus {
    border-color: var(--control-primary-color);
  }

  &:disabled {
    border-color: var(--control-border-color) !important;
  }

  @nest .root.danger & {
    border-color: var(--control-danger-color);

    &:hover {
      border-color: var(--control-danger-color-hover);
    }

    &:active, &:focus {
      border-color: var(--control-danger-color-active);
    }
  }

  @nest .disabled &, &:disabled {
    cursor: not-allowed;
    background-color: #fbfafa;
    color: var(--control-border-color);

    &::placeholder {
      color: var(--control-border-color);
    }

    &, &:hover, &:active, &:focus {
      border-color: var(--control-border-color);
    }
  }
}

.rootSecondary {
  & .input {
    /*
    border-color: var(--secondaryColor);
    */

    &:hover {
      border-color: var(--secondaryHoverColor);
    }

    &:active {
      border-color: var(--secondaryActiveColor);
    }

    &:focus {
      border-color: var(--secondaryActiveColor);
    }
  }
}

.disabled {
  & .icon {
    fill: var(--control-border-color);
  }
}
