@import url('../vars.css');

.background {
  background-image: linear-gradient(
    90deg,
    #eeeeee 0px,
    #f8f8f8 40px,
    #eeeeee 80px
  );
  background-size: 1400px;
}

.blueBackground {
  background-image: linear-gradient(
    90deg,
    #e5f1ff 0px,
    #f8f8f8 40px,
    #e5f1ff 80px
  );
  background-size: 1400px;
}

.root {
  --rlsiAttributeHeight: 12px;
  --rlsiAnimationDuration: 4s;
  --rlsiAnimationStartPosition: -100px;
  --rlsiAnimationEndPosition: 900px;

  background-color: white;
  border: 1px solid transparent;
  border-radius: var(--rliBorderRadius);

  padding: var(--rliBlockPadding) var(--rliInlinePadding);
}

.header {
  /*
  height: var(--rliHeaderHeight);
  */
  composes: blueBackground;
  height: 30px;
  border-radius: var(--rliBorderRadius) var(--rliBorderRadius) 0 0;

  animation: headerAnimation var(--rlsiAnimationDuration) infinite;
}

.content {
  padding: 20px var(--rliInlinePadding) 0;
}

.description {
  height: 20px;
  margin-bottom: 12px;
  composes: background;
  border-radius: var(--rliBorderRadius);

  animation: descriptionAnimation var(--rlsiAnimationDuration) infinite;
}

.attribute {
  display: flex;
  flex-wrap: nowrap;

  & + & {
    margin-top: 8px;
  }
}

.attributeKey {
  height: var(--rlsiAttributeHeight);
  flex: 0 0 50px;
  composes: background;
  margin-right: 16px;
  border-radius: var(--rliBorderRadius);

  animation: attributeKeyAnimation var(--rlsiAnimationDuration) infinite;
}

.attributeValue {
  height: var(--rlsiAttributeHeight);
  flex: 1 1 auto;
  composes: background;
  border-radius: 4px;

  animation: attributeValueAnimation var(--rlsiAnimationDuration) infinite;
}

@keyframes headerAnimation {
  0% {
    background-position: var(--rlsiAnimationStartPosition);
  }

  40%,
  100% {
    background-position: var(--rlsiAnimationEndPosition);
  }
}

@keyframes descriptionAnimation {
  0% {
    background-position: calc(
      var(--rlsiAnimationStartPosition) + var(--rliInlinePadding)
    );
  }

  40%,
  100% {
    background-position: calc(
      var(--rlsiAnimationEndPosition) - var(--rliInlinePadding)
    );
  }
}

@keyframes attributeKeyAnimation {
  0% {
    background-position: calc(
      var(--rlsiAnimationStartPosition) + var(--rliInlinePadding)
    );
  }

  40%,
  100% {
    background-position: calc(
      var(--rlsiAnimationEndPosition) - var(--rliInlinePadding)
    );
  }
}

@keyframes attributeValueAnimation {
  0% {
    background-position: calc(
      var(--rlsiAnimationStartPosition) + var(--rliInlinePadding) - 50px - 16px
    );
  }

  40%,
  100% {
    background-position: calc(
      var(--rlsiAnimationEndPosition) - var(--rliInlinePadding) - 50px - 16px
    );
  }
}
