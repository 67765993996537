.root {
  --tl-color: black;
  --tl-touched-color: var(--primary-color);

  line-height: 1.25;
}

.link {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: var(--tl-color);
  text-decoration: unset;

  transition: color 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:visited {
    text-decoration: unset;
    color: var(--tl-color);
  }

  &:hover {
    text-decoration: unset;
    color: var(--tl-touched-color);
  }

  &:active {
    color: var(--tl-touched-color);
  }
}

.rootSecondary {
  --tl-touched-color: var(--secondaryColor);
}
