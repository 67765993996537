.root {
  --color: #96281b;

  color: var(--color);
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.iconWrapper {
  flex: 0 0 auto;
  margin-right: 6px;
}

.icon {
  fill: var(--color);
  width: 16px;
  height: 16px;
}

.message {
  color: var(--color);
  min-width: 0;
  flex: 1 1 auto;
  line-height: 1.25;
  font-size: 14px;
  overflow-wrap: anywhere;
  word-break: break-all;
}

.detail {
  font-size: 12px;
  color: var(--color);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  max-height: 50px;
  overflow-wrap: anywhere;
  word-break: break-all;
}
