.toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;

  line-height: 1;
}

.toolbarBlock {
  flex: 0 0 auto;
  line-height: 1;

  & + & {
    margin-left: 8px;
  }

  &.flex {
    flex-grow: 1;
    flex-shrink: 1;
  }
}
