.root {
  display: block;
  font-size: 0.9rem;
  line-height: 1.25;

  overflow-wrap: break-word;
  /*
  word-break: break-all;
  */
  word-break: break-word;
}

.rootSmall {
  font-size: 0.85rem;
}

.part {
  vertical-align: middle;
}

.link {
  vertical-align: middle;
  /*
  margin: 0 2px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
  */
}
