.root {
  --rcChipMargin: 6px;
  --rcChipMarginNegative: calc(-1 * var(--rcChipMargin));

  display: block;
}

.chipContainerWrapper {
  margin-right: var(--rcChipMarginNegative);
  margin-bottom: var(--rcChipMarginNegative);
}

.chipContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.chip {
  margin-right: var(--rcChipMargin);
  margin-bottom: var(--rcChipMargin);
}
