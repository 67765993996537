.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  line-height: 40px;
  background-color: unset;
  box-sizing: border-box;
  outline: 0;

  border: unset;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  padding: 0 1em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: background-color 0.15s cubic-bezier(0.165, 0.84, 0.44, 1),
    color 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);

  & .uppercased {
    text-transform: uppercase;
  }

  &.small {
    height: 32px;
    font-size: 0.9rem;
    padding: 0 0.75em;
  }

  &.large {
    height: 46px;
  }
}

.wrapper {
  line-height: 1;
}

.iconed {
  padding: 0 0.75em;
}

.icon {
  width: 16px;
  height: 16px;
}

.disabled {
  cursor: not-allowed;
}

.contained {
  border-color: var(--control-primary-color);
  background-color: var(--control-primary-color);
  color: var(--control-primary-accent-color);

  &.primary {
    background-color: var(--control-primary-color);
    color: var(--control-primary-accent-color);

    &:hover {
      background-color: var(--control-primary-color-hover);
    }

    &:active {
      background-color: var(--control-primary-color-active);
    }

    &.disabled {
      &,
      &:hover,
      &:active {
        background-color: var(--control-primary-color-disabled);
      }
    }
  }

  &.danger {
    border-color: var(--control-danger-color);
    background-color: var(--control-danger-color);
    color: var(--control-danger-accent-color);

    &:hover {
      background-color: var(--control-danger-color-hover);
    }

    &:active {
      background-color: var(--control-danger-color-active);
    }

    &.disabled {
      &,
      &:hover,
      &:active {
        background-color: var(--control-danger-color-disabled);
      }
    }
  }

  &.iconed {
    & .icon {
      fill: var(--control-primary-accent-color);
    }
  }
}

.outlined {
  border-width: 1px;
  border-style: solid;
  background-color: unset;
  border-color: var(--control-primary-color);
  color: var(--control-primary-color);

  &.primary {
    border-color: var(--control-primary-color);
    color: var(--control-primary-color);

    &:hover {
      border-color: var(--control-primary-color-hover);
      color: var(--control-primary-color-hover);
    }

    &:active {
      border-color: var(--control-primary-color-active);
      color: var(--control-primary-color-active);
    }

    &.disabled {
      &,
      &:hover,
      &:active {
        border-color: var(--control-primary-color-disabled);
        color: var(--control-primary-color-disabled);
      }
    }
  }

  &.danger {
    border-color: var(--control-danger-color);
    color: var(--control-danger-color);

    &:hover {
      border-color: var(--control-danger-color-hover);
      color: var(--control-danger-color-hover);
    }

    &:active {
      border-color: var(--control-danger-color-active);
      color: var(--control-danger-color-active);
    }

    &.disabled {
      &,
      &:hover,
      &:active {
        border-color: var(--control-danger-color-disabled);
        color: var(--control-danger-color-disabled);
      }
    }
  }
}

.text {
  border-width: 0;
  border-color: unset;
  background-color: unset;
  padding: 0.25em 0.5em;

  &.primary {
    color: var(--blue-color-500);

    & .icon {
      fill: var(--blue-color-500);
    }

    &:hover {
      color: var(--blue-color-700);

      & .icon {
        fill: var(--blue-color-700);
      }
    }

    &:active {
      color: var(--blue-color-800);

      & .icon {
        fill: var(--blue-color-800);
      }
    }

    &.disabled {
      &,
      &:hover,
      &:active {
        color: var(--blue-color-200);

        & .icon {
          fill: var(--blue-color-200);
        }
      }
    }
  }

  &.danger {
    color: var(--red-color-600);

    & .icon {
      fill: var(--red-color-500);
    }

    &:hover {
      color: var(--red-color-700);
      & .icon {
        fill: var(--red-color-600);
      }
    }

    &:active {
      color: var(--red-color-800);
      & .icon {
        fill: var(--red-color-700);
      }
    }

    &.disabled {
      &,
      &:hover,
      &:active {
        color: var(--red-color-200);

        & .icon {
          fill: var(--red-color-200);
        }
      }
    }
  }
}

.uppercased {
  text-transform: uppercase;
}
