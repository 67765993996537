:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  position: relative;
  padding-right: var(--slib-inline-padding);
  padding-right: 4px;

  @media (min-width: __small) {
    padding-left: 6px;
  }

  @media (min-width: __medium) {
    padding-left: var(--slib-inline-padding);
  }
}

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.checkboxWrapper {
  line-height: 1;
  margin-right: 10px;
  flex: 0 0 auto;

  @media (min-width: __medium) {
    margin-right: 12px;
  }
}

.data {
  flex: 1 1 auto;
  min-width: 0;
  line-height: 1.15;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  min-height: 24px;
}

.link {
  flex: 1 1 auto;
}

.chipContainer {
  @media (min-width: __small) {
    margin-left: 8px;

    flex-wrap: nowrap;
    flex: 0 0 auto;
  }
}

.details {
  margin-top: 4px;
}

.description {
  margin-bottom: 8px;
  color: var(--gray-color-600);
  font-size: 0.8em;
  line-height: 1.35;

  word-break: normal;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;

  @nest .chipContainer + & {
    margin-top: 8px;
  }
}

.firstAttribute {
}

.secondAttribute {
  @media (min-width: __small) {
    grid-column: 2 / 3;
  }
}

.attributesWrapper {
  margin-top: 8px;
}

.attributesWrapperGrid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 12px;
  row-gap: 8px;

  @media (min-width: __small) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

/*
.actions {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-items: flex-end;
  justify-content: flex-end;
  flex-wrap: nowrap;
  margin: 16px 0 0 0;
}

.actionButton {
  flex-shrink: 1;
  font-size: 13px;

  & + & {
    margin-left: 8px;
  }
}

.checkboxWrapper {
  line-height: 1;
  margin-right: 12px;
  flex: 0 0 auto;
}
*/
