.root {
  border-radius: 4px;
}

.contained {
  &.primary {
    /*
    color: var(--control-primary-accent-color);

    &:hover {
      background-color: var(--control-primary-color-hover);
    }

    &:active {
      background-color: var(--control-primary-color-active);
    }
    */

    &.disabled {
      &,
      &:hover,
      &:active {
        background-color: var(--control-primary-color-disabled);
      }
    }
  }

  &.danger {
    /*
    color: var(--control-danger-accent-color);

    &:hover {
      background-color: var(--control-danger-color-hover);
    }

    &:active {
      background-color: var(--control-danger-color-active);
    }
    */

    &.disabled {
      &,
      &:hover,
      &:active {
        background-color: var(--control-danger-color-disabled);
      }
    }
  }

  &.secondary {
    /*
    background-color: var(--secondaryColor);
    color: var(--secondaryAccentColor);

    &:hover {
      background-color: var(--secondaryHoverColor);
    }

    &:active,
    &:focus {
      background-color: var(--secondaryActiveColor);
    }
    */

    &.disabled {
      &,
      &:hover,
      &:active {
        background-color: var(--secondaryDisabledColor);
      }
    }
  }
}

.text {
  &.primary {
    &.disabled {
      color: var(--control-primary-color-disabled);
    }
  }
}
