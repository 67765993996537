:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  width: 100%;
  min-height: 100%;
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
}

.header {
  flex: 0 0 auto;
  width: 100%;
  position: relative;
}

.content {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: relative;
  padding-bottom: 40px;
}
