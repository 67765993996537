.input {
  padding-right: 60px !important;
}

.suffix {
  max-width: 48px !important;
}

.counter {
  display: inline-block;
  font-size: 0.8em;
  color: #828282;
  line-height: 1;
  white-space: nowrap;

  text-overflow: ellipsis;
  overflow: hidden;
}
