.root {
  min-height: 100vh;
  background-color: #e5f3ff;
}

.wrapper {
  position: relative;
  z-index: 20;

  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
}

.header {
  flex: 0 0 auto;
}

.layout {
  position: relative;
  flex-grow: 1;
  padding-bottom: 60px;
}

.layoutWrapper {
  position: relative;
}
