.root {
  /*
  display: grid;
  grid-template-columns: 40px minmax(100px, 1fr);
  row-gap: 8px;
  */
}

.item {
  & + & {
    margin-top: 10px;
  }
}
