:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.root {
  --login-page-external-form-color: #e5f3ff;

  min-height: 100%;
  background-color: #f8fbff;
  position: relative;
  display: flex;
  flex-direction: column;
}

.rootWrapper {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
}

.header {
  position: relative;
  z-index: 20;
}

.contentLayout {
  width: 100%;
  padding-top: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: relative;
}

.logoContainer {
  margin-bottom: 24px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;

  position: relative;
  z-index: 10;
}

.logo {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
  object-fit: cover;

  @media (min-width: __medium) {
    height: 56px;
  }
}

.formContainerLayout {
  width: 100%;
  max-width: 460px;
  min-height: 320px;
  background: center/auto no-repeat
    url('src/assets/svg/assets/login-form-background.svg');

  position: relative;

  display: flex;
  /*
  align-items: center;
  */
  padding-top: 50px;
  justify-content: center;
}

.formContainerLayoutWrapper {
  width: 100%;
}

.formContainerWrapper {
  width: 100%;
  /*
  min-height: 320px;
  */
  display: flex;
  justify-content: center;
  align-items: center;
}

.formContainer {
  /*width: 100%; */
  max-width: 320px;
  flex: 1 1 auto;

  position: relative;
  z-index: 10;
}

.title {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;

  @media (min-width: __small) {
    font-size: 1.5rem;
  }
}

.form {
  position: relative;
  z-index: 20;
}

.inputRoot {
  width: 100%;
}

.input {
  border-color: #4f4f4f;
  background-color: #f8fbff;
}

.inputIcon {
  fill: #4f4f4f;
}

.buttons {
  width: 100%;
  margin-top: 36px;
}

.submitButton {
  width: 100%;
  text-transform: uppercase;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.errorBlock {
  background-color: var(--login-page-external-form-color);
  margin-top: 8px;
  border-radius: 4px;
}

.forgotPasswordContainer {
  text-align: right;
  margin-top: 8px;
}

.forgotPasswordLink {
  background-color: var(--login-page-external-form-color);
  line-height: 1.25;
  border-radius: 4px;
}
