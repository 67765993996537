.root {
  line-height: 1;
}

/** primary */
.buttonContent {
  max-width: 100px;
  color: var(--primaryLightAccentColor);
}

/** primary */
.icon {
  color: var(--primaryLightAccentColor);
}

.secondary {
  & .buttonContent {
    color: var(--secondaryLightAccentColor);
  }

  & .icon {
    fill: var(--secondaryLightAccentColor);
  }
}
