:import('~src/styles/breakpoints.module.css') {
  __small: s;
  __medium: md;
}

.formContainerLayout {
  --externalBackgroundColor: #e5f3ff;

  width: 100%;
  max-width: 460px;
  min-height: 320px;
  background: center/auto no-repeat
    url('src/assets/svg/assets/login-form-background.svg');

  position: relative;

  display: flex;
  /*
  align-items: center;
  */
  padding-top: 50px;
  justify-content: center;
}

.formContainerLayoutWrapper {
  width: 100%;
}

.formContainerWrapper {
  width: 100%;
  /*
  min-height: 320px;
  */
  display: flex;
  justify-content: center;
  align-items: center;
}

.formContainer {
  /*width: 100%; */
  max-width: 320px;
  flex: 1 1 auto;

  position: relative;
  z-index: 10;
}

.title {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;

  @media (min-width: __small) {
    font-size: 1.5rem;
  }
}

.form {
  position: relative;
  z-index: 20;
}

.buttons {
  width: 100%;
  margin-top: 24px;
}

.errorBlock {
  margin-top: 8px;
  background-color: var(--externalBackgroundColor);
  border-radius: 4px;
}

.forgotPasswordContainer {
  text-align: right;
  margin-top: 8px;
}

.forgotPasswordLink {
  line-height: 1.25;
  background-color: var(--externalBackgroundColor);
  border-radius: 4px;
}
